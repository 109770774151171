import React from "react"
import TopNav from "./top-nav"

require("typeface-poppins")

const COLORS = {
  "/about/": "#ffffff",
  "/about": "#ffffff",
  "/project/zhihu-profile/": "#bfd3d8",
  "/project/zhihu-profile": "#bfd3d8",
  "/project/google-paper-io/": "#DFE6E8",
  "/project/google-paper-io": "#DFE6E8",
  "/project/google-feedback/": "#DBE2F0",
  "/project/google-feedback": "#DBE2F0",
  "/project/google-system-services/": "#F9ECEA",
  "/project/google-system-services": "#F9ECEA",
}

const Layout = ({ location, children }) => {
  const bgColor = COLORS[location.pathname]
  return (
    <div>
      <header style={{ backgroundColor: bgColor ? bgColor : "#FAEFEC" }}>
        <div>
          <TopNav location={location}></TopNav>
          <div className="max-w-4/5 xl:max-w-screen-lg mx-auto">
            {children[0]}
          </div>
        </div>
      </header>
      <main className="max-w-4/5 xl:max-w-screen-lg mx-auto">
        {children[1]}
      </main>
      <footer className="h-16 sm:h-24 flex bg-black place-content-center mt-24 sm:mt-48">
        <div className="text-white text-base sm:text-lg self-center">
          Reach out to me at{" "}
          <a className="underline" href="mailto:hey.menghan@gmail.com">
            hey.menghan@gmail.com
          </a>
        </div>
      </footer>
    </div>
  )
}

export default Layout
