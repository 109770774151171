import React from "react"
import { Link } from "gatsby"

import selectedBg from "../images/nav_selected_bg.svg"

const NavItem = ({ location, linkTo, children }) => {
  const bg = location.pathname === linkTo ? `url(${selectedBg})` : ``
  return (
    <li
      className="p-6 mr-6 sm:mr-10 bg-contain bg-no-repeat bg-center"
      style={{
        backgroundImage: bg,
      }}
    >
      <Link to={linkTo} className="text-base font-medium" activeClassName="nav">
        {children}
      </Link>
    </li>
  )
}

const navItems = [
  { id: `/`, text: `Work` },
  { id: `/about`, text: `About` },
]

const TopNav = ({ location }) => {
  return (
    <div className="max-w-4/5 xl:max-w-screen-lg mx-auto py-8 sm:py-14">
      <nav>
        <ul className="list-none flex">
          {navItems.map(({ id, text }) => (
            <NavItem key={id} linkTo={`${id}`} location={location}>
              <div>{text}</div>
            </NavItem>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default TopNav
